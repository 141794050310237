<template>
    <div>

       
        <div class="super">
            <Header />
            <div class="holder">
                <div class="container-fluid">
                    <div class="mt-3 mb-3 d-flex">
                        <span style="cursor: pointer;" @click="goBack()" class="mr-2"><i class="fa fa-arrow-left"></i></span>
                        <span style="cursor: pointer;" @click="goBack()">Back</span>
                    </div>


                    <div style="margin-bottom: 30px;">
                        <div class="image-gallerey-view">
                            <div class="row">
                                <div class="col-md-6" v-if="propDetails.images != null">
                                    <!-- <img :src="propImages[0]" alt="Tet Image"> -->
                                    <div class="main-img">
                                        <img v-if='propDetails.images.length == 0' class="w-100" src="../assets/images/prop5.svg" alt="main-image">
                                        <img v-else class="w-100" :src="propDetails.images[0]" alt="main-image">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row" v-if="propDetails.images">
                                        <!-- <img :src="propDetails.images[0]" /> -->
                                        <div class="col-md-6 mb-2" v-for="(item, index) in propDetails.images.slice(1, 5)" :key="index">
                                            <div class="small-img" :class="{ 'last-img': index === 3 }">
                                                <h6 class="number-text" v-if="index == 3">+ {{ propDetails.images.length }}</h6>
                                                <img class="w-100" :src="item" alt="others">
                                            </div>
                                        </div>
                                    
                                        <div class="col-md-6 mb-2" v-if="propDetails.images == null">
                                            <div class="small-img last-img">
                                                <h6 class="number-text">+ 4</h6>
                                                <img class="w-100" src="../assets/images/property-image-placeholder.svg" alt="main-image">
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-6 mb-2">
                                            <div class="small-img">
                                                <img class="w-100" src="../assets/images/property-image-placeholder.svg" alt="main-image">
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <div class="small-img">
                                                <img class="w-100" src="../assets/images/property-image-placeholder.svg" alt="main-image">
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <div class="small-img">
                                                <img class="w-100" src="../assets/images/property-image-placeholder.svg" alt="main-image">
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                    <div class="row">
                            <div class="col-md-9">
                                <div class="prop-detail-holder mt-3">
                                    <div class="title-section" v-if="propDetails">
                                        <h4 class="title">{{ propDetails.title }}</h4>
                                        <h6><img src="../assets/images/map-point.svg" alt="location"> {{ propDetails.city }}, {{ propDetails.state }}</h6>
                                    </div>
                                    <div class="prop-price-spec">
                                        <div>
                                            <p>Price</p>
                                            <!-- <h6>₦{{ Number(propDetails.amount).toLocaleString() || 0.00}}</h6> -->
                                            <h6>₦{{ Number(propDetails.price).toLocaleString() }}</h6>
                                        </div>
                                        <div>
                                            <p>Bedrooms</p>
                                            <!-- <h6>{{ propDetails.specific_data.bedrooms }}</h6> -->
                                            <h6>{{ propDetails.bedrooms }}</h6>
                                        </div>
                                        <div>
                                            <p>Bathrooms</p>
                                            <!-- <h6>{{ propDetails.specific_data.bathrooms }}</h6> -->
                                            <h6>{{ propDetails.bathrooms }}</h6>
                                        </div>
                                        <div>
                                            <p>Property type</p>
                                            <!-- <h6>{{ propDetails.specific_data.typename }}</h6> -->
                                            <h6>{{ propDetails.typename }}</h6>
                                        </div>
                                        <div v-if="propDetails.squarefootage !== null">
                                            <p>Square feet</p>
                                            <!-- <h6>{{ propDetails.specific_data.squarefootage}}</h6> -->
                                            <h6>{{ propDetails.squarefootage }} ft</h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="description-holder" v-if="propDetails.description !== null">
                                    <h4 class="header-text-top">Description</h4>
                                    <!-- <h6 v-if="propDetails.specific_data">{{ propDetails.specific_data.description }}</h6> -->
                                    <h6>{{ propDetails.description }}</h6>
                                    <!-- <p>See More...</p> -->
                                </div>

                                <div class="offerings-holder mt-4" v-if="propDetails.facilities && propDetails.facilities.length > 0">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="">
                                                <h4  class="header-text-top">Offerings available here</h4>
                                                <div class="row">
                                                    <div class="col-12 col-sm-6 col-md-4 offering-list"  v-for="(facility, index) in propDetails.facilities"
                                                        :key="index">
                                                        <p>{{facility}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="adddress-holder mt-4">
                                    <img style="width: 20px; padding-top: 5px;" src="../assets/images/map-point.svg" alt="location">
                                    <div class="prop-address">
                                        <h5>Property address</h5>
                                        <h6>{{ propDetails.address }}</h6>
                                        <!-- <h6 v-if="propDetails.specific_data">{{ propDetails.specific_data.address }}</h6> -->
                                    </div>
                                </div>

                                <div class="description-holder d-none">
                                    <h4 class="header-text-top">Terms & conditions</h4>
                                    <h6>Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla 
                                        laoreet vel nullam scelerisque nulla aliquam sed.
                                        Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit.
                                        Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet 
                                        vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst.
                                        In adipiscing congue id sit Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices
                                        porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit
                                    </h6>
                                    <p data-toggle="modal" data-target="#termscondition">See More...</p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div>
                                    <!-- <AgentSheltaContact /> -->
                                    <div class="agent-details">
                                        <div class="details-holder" v-if="propDetails.smartAgentObject !== null">
                                            <div class="agent-image" v-if="propDetails.smartAgentObject">
                                                <img v-if="propDetails.smartAgentObject.passportfile !== null && propDetails.smartAgentObject !== null" :src="propDetails.smartAgentObject.passportfile" alt="Profile-image">
                                                <img v-else src="../assets/images/user-black.svg" alt="Profile-image">
                                                <img v-if="propDetails.smartAgentObject == null" src="../assets/logo.svg" alt="Profile-image">
                                                
                                            </div>
                                            <div class="contact-info" v-if="propDetails.smartAgentObject">
                                                <h5 v-if="propDetails.smartAgentObject !== null">{{ propDetails.smartAgentObject.firstname }} {{ propDetails.smartAgentObject.firstname }}</h5>
                                                <h5 v-else>Shelta Panacea</h5>
                                                <h6>Abuja, Nigeria</h6>
                                                <!-- <h6>0</h6> -->
                                                <!-- <a href="https://api.whatsapp.com/send?phone=2348061796909"
                                                ><img
                                                    src="../assets/images/whatsApp-blue.svg"
                                                    alt="whatsapp"
                                                /></a> -->
                                                <button class="btn"  v-if="propDetails.smartAgentObject !== null">
                                                    <a :href="whatsappLink">
                                                        Contact Agent
                                                    </a>
                                                    
                                                </button>
                                                <button class="btn"  v-if="propDetails.smartAgentObject == null">
                                                    <a href="https://api.whatsapp.com/send?phone=2348061796909">
                                                        Contact Shalta
                                                    </a>
                                                    
                                                </button>
                                            </div>
                                        </div>
                                        <!-- <p>{{ requestSchdule }}</p>  -->
                                        <div class="request-options" :class="propDetails.smartAgentObject == null ? 'rm' : ''">
                                            <button class="btn" :class="[requestSchdule == '1' ? 'active-options' : '']" @click="requestSchdule = '1'">Request Info.</button>
                                            <button class="btn" :class="[requestSchdule == '2' ? 'active-options' : '']" @click="requestSchdule = '2'">Schedule an inspection</button>
                                        </div>

                                        <div class="request-form" v-if="requestSchdule == '1' || requestSchdule == '2'">
                                            <div v-if="requestSchdule === '1'">
                                                <h5>Request info</h5>
                                                <h6>Provide your details</h6>
                                            </div>
                                            <div v-if="requestSchdule == '2'">
                                                <h5>Schedule an inspection</h5>
                                                <h6>Mon - Sat | 8AM - 5PM</h6>
                                            </div>
                                            <div class="">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <input
                                                                v-model="fname"
                                                                type="text"
                                                                class="form-control input-bar"
                                                                id="fname"
                                                                name="fname"
                                                                placeholder="First name"
                                                            />
                                                        </div>
                                                        <div class="col-md-6">
                                                            <input
                                                                v-model="lname"
                                                                type="text"
                                                                class="form-control input-bar"
                                                                id="lname"
                                                                name="lname"
                                                                placeholder="Last name"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        v-model="email"
                                                        type="email"
                                                        class="form-control input-bar"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Your email address"
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        v-model="phone"
                                                        type="number"
                                                        class="form-control input-bar"
                                                        id="phone"
                                                        name="phone"
                                                        placeholder="Your phone number"
                                                    />
                                                </div>
                                                <div class="form-group" v-if="requestSchdule === '2'">
                                                    <label class="label" for="">Select day and time</label>
                                                    <div class="row">
                                                        <div class="col-md-6 col-sm-12">
                                                            <!-- <select class="form-control input-bar" name="day" id="day">
                                                                <option value="" selected disabled>Select day</option>
                                                                <option value="">Monday</option>
                                                                <option value="">Tuesday</option>
                                                                <option value="">Wednessday</option>
                                                            </select> -->
                                                            <select
                                                                v-model="tourDay"
                                                                name="tourday"
                                                                class="form-control input-bar"
                                                                id="date"
                                                            >
                                                                <option value="" disabled>Select tour day</option>
                                                                <option
                                                                v-for="(item, index) in tourdates"
                                                                :key="index"
                                                                v-bind:value="item"
                                                                >{{ item }}</option
                                                                >
                                                            </select>
                                                        </div>
                                                        <div class="col-md-6 col-sm-12">
                                                            <select
                                                                v-model="tourTime"
                                                                name="tourtime"
                                                                class="form-control input-bar"
                                                                id="time"
                                                            >
                                                                <option value="" disabled>Select tour time</option>
                                                                <option
                                                                v-for="(item, index) in tourtimes"
                                                                :key="index"
                                                                v-bind:value="item.time"
                                                                >{{ item.time }}</option
                                                                >
                                                            </select>
                                                            <!-- <input class="form-control input-bar" v-model="tourTime" type="time" name="time" id="time" placeholder="Select time"> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <textarea class="textarea form-control" v-model="message" name="message" id="message" cols="30" rows="5" placeholder="Your message (optional)"></textarea>
                                                </div>

                                                <div class="form-group container">
                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                    <label class="form-check-label label" for="exampleCheck1">Create an account with these details</label>
                                                </div>

                                                <div class="form-group contCan-btn">
                                                    <button @click="requestSchdule = ''" class="btn">Cancel</button>
                                                    <button @click="scheduletour()" :disabled="requestBtnDisabled" class="btn">{{requestBtnDisabled ? 'Processing...' : 'Continue' }}</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="border-text">
                                            Or
                                        </div>

                                        <button class="rent-now btn" @click="moveTo(propDetails.pin)">Rent now</button>

                                        <p class="disclaimer"><span>Disclaimer:</span>Information in this listing is deemed reliable but not guaranteed. Verify details independently before making decisions. Properties are subject to change or withdrawal.</p>
                                    </div>
                                </div>
                            </div>    
                    </div>

                    </div>
                    
                    <!-- terms and condtion modal starts -->
                    <div class="modal fade" tabindex="-1" id="termscondition">
                        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title"></h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <p class="modal-top-text">Terms & Condtions</p>

                                    <h6 class="modal-content-body">
                                        Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit.
                                        Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit 
                                        Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit
                                        Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit 
                                        Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit
                                    </h6>
                                </div>
                        
                            </div>
                        </div>
                    </div>
                    <!-- terms and condtion modal ends -->
                </div>
            </div>
        </div>
        <FooterMain />
    </div>
</template>

<script>
 import Header from "../views/Header";
 import FooterMain from "../views/FooterMain";
 import { Service } from "../store/service";
 const Api = new Service();

export default {
    name: "Details",
    components: {
    Header,
    FooterMain,
  
    },
   
    data() {
        return {
            // propDetails: [],
            tourdates: [],
            propImages: null,
            oppid: "",
            requestSchdule: '',
            requestBtnDisabled: false,
            fname: "",
            lname: "",
            email: "",
            phone: "",
            message: "",
            tourTime: "",
            tourDay: "",
            tourtimes: [
                { time: "10AM" },
                { time: "11AM" },
                { time: "12PM" },
                { time: "1PM" },
                { time: "2PM" },
                { time: "3PM" },
                { time: "4PM" }
            ],
        }
    },
    computed: { 
        propDetails() {
        // Fetch the value from localStorage
        // return localStorage.getItem("myData") || "No data found";
            console.log("jjjjfjfj", JSON.parse(localStorage.getItem('__propertyDetails')))
            return JSON.parse(localStorage.getItem('__propertyDetails') || 'No details found');
        },
        whatsappLink() {
            return `https://api.whatsapp.com/send?phone=${this.propDetails.smartAgentObject.phone}`;
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        moveTo(id) {
        this.$router
            .push({
            path: `/dashboard/rent/${id}`
            })
            .catch(() => {});
        },
        async scheduletour(data) {
            this.info = true;
            this.loader = true;
            if (
            this.fname === "" ||
            this.lname === "" ||
            this.email == "" ||
            this.phone === "" ) {
                this.info = true;
                this.loader = false;
            return this.$store.commit("setApiFailed", "Fill all required fields");
            } if ((this.requestSchdule === '2' ) && (this.tourTime === '' || this.tourDay === '')) {
                return this.$store.commit("setApiFailed", "Select day and time");
            } else {
                try {
                    this.requestBtnDisabled = true;
                    await Api.postRequest(`bookatour`, {
                        firstname: this.fname,
                        lastname: this.lname,
                        email: this.email,
                        phone: this.phone,
                        message: this.message,
                        propertyid: this.propDetails.id,
                        datebooked: this.datebooked,
                        timebooked: this.timebooked
                    })
                        .then(res => {
                        if (res.data.success) {
                            this.tourData = res.data;
                            this.$store.commit("setApiSuccess", "Success");
                            this.schedule = data;
                            this.loader = false;
                            this.requestBtnDisabled = false;
                            this.requestSchdule = '';

                            this.fname = "";
                            this.lname = "";
                            this.email = "";
                            this.phone = "";
                            this.message = "";
                            this.datebooked = "";
                            this.timebooked = "";
                        }
                        if (res.data.error) {
                            this.$store.commit("setApiFailed", "Error");
                        }
                        })
                        .catch(err => {
                            console.log("err", err);
                            this.loader = false;
                            this.requestBtnDisabled = false;
                            this.$store.commit("setApiFailed", "Error");

                        });
                    } finally {
                    // console.log("closed");
                }
            }
        },
        async propertyDetails() {
            try {
                await Api.getRequest(`fetchpropertybyid/${12}`).then(res => {
                console.log("property details", res);
                // this.properties = res.data.properties;
        
                }).catch(err => {
                    console.log(err);
                });
            } finally {
                // console.log("Closed connection");
            }
        },
        getToreDates() {
            Api.getRequest("getdatesfortour")
                .then(res => {
                this.tourdates = res.data.dates;
                })
                .catch(err => {
                console.log("err", err);
            });
        },
    },
    mounted() {
        this.getToreDates();
    },
}
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;


.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  padding-top: 10rem;
//   background: $secondary;
}

.main-img {
    height: 100%;
    img {
        border-radius: 8px;
        height: 100%;
        max-height: 438px;
        min-height: 400px;
        object-fit: cover;
        // height: 195px;
    }
}

.small-img {
    // min-height: 215px;
    // height: 100%;
    // width: 100%;
    img {
        border-radius: 8px;
        // max-height: 215px;
        object-fit: cover;
        // max-height: 215px;
        // height: 100%;
        min-height: 195px;
        max-height: 215px;
    }
}

// .last-img {
//     // background-color: rgba(0, 0, 0, 0.5);
//     background-color: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(0, 0, 0, 0.5);
// }

.rm {
    margin-top: 0;
}
.last-img {
    position: relative;
}
.last-img::before {
    border-radius: 8px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    pointer-events: none; /* Allow interactions with the underlying image */
}

.number-text {
    position: absolute;
    top: 35%;
    left: 35%;
    color: $white;
    font-family: Lato;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    line-height: 54px;
}

.title-section {
    h4 {
        font-family: Lato;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: left;
        color: #2B3352
    }
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: $asheGrey;
    }
}
.prop-price-spec {
    border: 1px solid #E2E8F0;
}

.header-text-top {
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #2B3352;
}

.prop-price-spec {
    display: grid;
    // justify-content: space-between;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    padding: 20px 40px;
    border-radius: 8px;
    margin-top: 20px;
    overflow-y: auto;
    // overflow-x: none;
}
.prop-price-spec > div {
    border-right: 1px solid #E2E8F0;
    padding-left: 20px;
    padding-right: 20px;
}
.prop-price-spec > div:first-child {
    padding-left: 0;
}
.prop-price-spec > div:last-child {
    border-right: none;
}
.prop-price-spec > div {
    p {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $asheGrey;
        margin-bottom: 15px !important;
    }
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #2B3352;
    }
}

.description-holder {
    margin-top: 30px;
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: justify;
        color: $asheGrey;
        margin-bottom: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    p {
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.5px;
        text-align: left;
        color: #000A2F;
        text-decoration: underline;
        cursor: pointer;
    }
}

.offerings-holder {
    border: 1px solid #E2E8F0;
    padding: 24px;
    border-radius: 8px;
}

.offering-list {
    p {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        text-align: left;
        color: $asheGrey;
    }
}

.adddress-holder {
    border: 1px solid #E2E8F0;
    padding: 24px;
    border-radius: 8px;
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.prop-address {
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin-bottom: 0;
    }
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 100;
        line-height: 21px;
        text-align: left;
        margin-bottom: 0;
    }
}
@media screen and (max-width: 599px) {
  .main-img {
    margin-bottom: 10px;
    img {
        max-height: 215px;
        object-fit: cover;
    }
  }
}

.stat-img {
  width: 25px;
}
.share-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #0033ea;
  border-color: #0033ea;
}
.share-btn-img {
  width: 20px;
}

.modal-top-text {
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;

}
.modal-content-body {
    margin-top: 10px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 200;
    line-height: 24px;
    text-align: justified !important;

}

.agent-details {
    margin-bottom: 40px;
    box-shadow: 0px 14px 31px 0px #C7C7C71A;
    border: 1px solid #E2E8F0;
    padding: 15px;  
    border-radius: 8px;
    margin-top: 20px;
}

.details-holder {
    display: flex;
    gap: 10px;
}
.agent-image {
    width: 88px;
    height: 88px;
    border-radius: 50px;
    border: 1px solid #E2E8F0;
    display: flex;
    justify-content: center;
    img {
        text-align: center;
        width: 68px;
    }
}

.contact-info {
    h6, h5 {
        font-family: Lato;
        font-size: 14px;
        line-height: 19.6px;
        text-align: left;
        color: $offblack;
    }
    h6 {
        font-weight: 400;
        margin-bottom: 0;
    }
    h5 {
        font-weight: 700;
    }   
    button {
        margin-top: 20px;
        background: $primary;
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.5px;
        text-align: center;
        color: $white;
        display: flex;
        box-shadow: none;
        outline: 0;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
}

.request-options {
    margin-top: 25px;
    border-top: 1px solid #E2E8F0;
    border-bottom: 1px solid #E2E8F0;
    display: flex;
    justify-content: center;
    padding: 5px 0;
    button:first-child {
        border-right: 1px solid #E2E8F0;
    }
    button {
        font-size: 14px;
        font-family: Lato;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        outline: 0;
        box-shadow: none;
        color: $primary;
    }
}

.active-options {
    color: #18328e !important;
}

.border-text {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;  
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.border-text::before,
.border-text::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #E2E8F0;
    margin: 0 10px;
}

.rent-now {
    background: $primary;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: $white;
    box-shadow: none;
    outline: 0;
    width: 100%;
    height: 48px;
}

.disclaimer {
    margin-top: 30px;
    span {
        font-weight: 600;
        color: #000000;
        padding-right: 5px;
    }
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $asheGrey;

}

.request-form {
    margin-top: 30px;
    text-align: left;
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: $offblack;
        margin-bottom: 0;
    }
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $asheGrey;
        margin-bottom: 0;
    }
    form {
        margin-top: 10px;
    }
}

.input-bar {
    height: 40px;
    border: 1px solid #E7E7E7;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #AAADBA;
    outline: 0;
    box-shadow: none;
}
.textarea {
    border: 1px solid #E7E7E7;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #AAADBA;
    outline: 0;
    box-shadow: none;

}
.label {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: $asheGrey;
}

.form-check-input {
    width: 15px;
    height: 15px;
}

.contCan-btn {
    display: flex;
    margin-top: 20px;
    gap: 10px;
    button {
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.5px;
        text-align: center;
        height: 32px;
        width: 100%;
        outline: 0;
        box-shadow: none;
    }
    button:first-child {
        color: $offblack;
        border: 1px solid $offblack;
    }
    button:nth-child(2) {
        color: $primary;
        border: 1px solid $primary;
    }
}


// Loader starts
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// Loader ends

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
